import CheckIcon from '@mui/icons-material/Check'
import CloudOffIcon from '@mui/icons-material/CloudOff'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'
import {
  Chip,
  ChipProps,
  Skeleton,
  Stack,
  Typography,
  useTheme,
  alpha,
  Tooltip,
  Box
} from '@mui/material'
import {useTranslation} from 'react-i18next'

import {getTranslationKey} from '../../utils/translation.utils'

import {SignalStatus, AssetPartSignal, SignalsAssetPartMap} from './types'

export const KilnMetricStatus = ({
  title,
  value,
  status,
  disabled,
  loading,
  onClick,
  signals,
  isConfig,
  translationPrefix = ''
}: {
  title?: string
  value?: string
  status?: SignalStatus | 'ModelBreach'
  disabled?: boolean
  loading?: boolean
  isConfig?: boolean
  onClick?: () => void
  signals?: AssetPartSignal[] | SignalsAssetPartMap
  translationPrefix?: string
}) => {
  const theme = useTheme()
  const {t} = useTranslation()

  const {color, backgroundColor, icon, defaultValue} = ((): {
    color: ChipProps['color']
    backgroundColor: string
    icon?: JSX.Element
    defaultValue: string
  } => {
    switch (true) {
      case status === 'Ok':
        return {
          color: 'success',
          backgroundColor: theme.palette.success.light,
          icon: <CheckIcon sx={{fontSize: 16}} />,
          defaultValue: t(getTranslationKey('kilnMetrics.status.ok', translationPrefix))
        }
      case status === 'Warning':
        return {
          color: 'warning',
          backgroundColor: theme.palette.warning.light,
          icon: <WarningIcon sx={{fontSize: 16}} />,
          defaultValue: t(getTranslationKey('kilnMetrics.status.warning', translationPrefix))
        }
      case status === 'Alarm':
        return {
          color: 'error',
          backgroundColor: theme.palette.error.light,
          icon: <ErrorIcon sx={{fontSize: 16}} />,
          defaultValue: t(getTranslationKey('kilnMetrics.status.alarm', translationPrefix))
        }
      case status === 'ModelBreach':
        return {
          color: undefined,
          backgroundColor: '#8C3CC9',
          icon: <WarningIcon style={{color: '#8C3CC9'}} sx={{fontSize: 16}} />,
          defaultValue: t(getTranslationKey('sensorList.predictionBreach', translationPrefix))
        }
      // UPM returns 'Undefined' for signals that have no thresholds defined
      case status === 'Undefined' || disabled:
        return {
          color: 'default',
          backgroundColor: theme.palette.grey[600],
          defaultValue: '-'
        }
      // no telemetry data at all
      default:
        return {
          color: 'default',
          backgroundColor: theme.palette.grey[600],
          defaultValue: 'No data',
          icon: <CloudOffIcon sx={{fontSize: 16}} />
        }
    }
  })()

  const influencedBy = signals
    ? Array.isArray(signals)
      ? signals.map((s) => `\n${s.id}.${s.description}`)
      : Object.keys(signals).map((key) => signals[key].map((s) => `\n${s.id}.${s.description}`))
    : ''

  return (
    <Stack gap={0.5} alignItems="flex-start">
      {title && (
        <Typography variant="caption" noWrap>
          {title}
        </Typography>
      )}
      <Tooltip
        title={
          signals ? (
            <Box sx={{fontSize: '14px', whiteSpace: 'pre-line'}}>
              {t(getTranslationKey('kilnMetrics.influencedBy', translationPrefix)) +
                '\n' +
                influencedBy}
            </Box>
          ) : isConfig ? (
            'Click to connect signals'
          ) : (
            ''
          )
        }
      >
        <Chip
          variant="outlined"
          color={color}
          icon={loading ? <Skeleton variant="circular" width={15} height={15} /> : icon}
          label={
            loading ? <Skeleton variant="rounded" width={60} height={18} /> : value || defaultValue
          }
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation()
            onClick?.()
          }}
          sx={{
            px: 0.5,
            background: alpha(backgroundColor, 0.04),
            fontSize: 16,
            fontWeight: 500,
            borderRadius: '4px',
            color: backgroundColor,
            border: `1.5px solid ${alpha(backgroundColor, 0.08)}`,
            '&&:hover': {
              background: alpha(backgroundColor, 0.08)
            },
            alignSelf: 'stretch'
          }}
        />
      </Tooltip>
    </Stack>
  )
}
